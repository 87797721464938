import { useMutation, useQuery, useQueryClient } from 'react-query';

import { AdminUserInvite, PaginatedResponse, UserPermission } from 'src/types';
import { ApiResponse, http } from '../http-common';

export type UserInviteResponse = ApiResponse & {
  invite?: AdminUserInvite;
};

export type UserInvitesResponse = ApiResponse & {
  invites: PaginatedResponse<AdminUserInvite>;
};

export const useDeleteUserInviteMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (id: string) => {
      const res = await http.delete<UserInviteResponse>(`/api/admin/user-invites/${id}`);
      return res.data;
    },
    {
      onSuccess: ({ invite }) => {
        queryClient.invalidateQueries(['admin/user-invites']);
      },
    }
  );
};

export type InviteUserInput = {
  email: string;
  role: string;
  permissions: UserPermission[];
};

export const useInviteUserMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (input: InviteUserInput) => {
      const res = await http.post<UserInviteResponse>('/api/admin/user-invites', input);
      return res.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['admin/user-invites']);
      },
    }
  );
};

export type ListUsersParams = {
  search?: string;
  limit: number;
  offset: number;
};

export const useListUserInvitesQuery = (params: ListUsersParams) => {
  return useQuery(['admin/user-invites', params], async () => {
    const res = await http.get<UserInvitesResponse>('/api/admin/user-invites', { params });
    return res.data.invites;
  });
};
