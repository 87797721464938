import { Button, Icon, Label, Modal, Table } from 'semantic-ui-react';

import { JobStatus, ScrubDataJob } from 'src/types';

const betaUrl = process.env.REACT_APP_SERVER_URL;

const ScrubDetailsModal = ({ id: jobId, status, queued, payload }: ScrubDataJob) => {
  return (
    <Modal
      trigger={
        <Button title="View Details" icon>
          <Icon name="eye" />
        </Button>
      }
    >
      <Modal.Header>Job Details</Modal.Header>
      <Modal.Content>
        {status === JobStatus.Error && typeof payload.error !== 'undefined' && payload.error.length > 0 && (
          <Label color="red">
            Error
            <Label.Detail>{payload.error}</Label.Detail>
          </Label>
        )}

        <Table definition>
          <Table.Body>
            <Table.Row>
              <Table.Cell style={{ width: 130 }}>Created At</Table.Cell>
              <Table.Cell>{queued}</Table.Cell>
            </Table.Row>

            {typeof payload.field_mappings !== 'undefined' && (
              <Table.Row>
                <Table.Cell>Field Mappings</Table.Cell>
                <Table.Cell>
                  {payload.field_mappings.map(h => (
                    <Label>{h}</Label>
                  ))}
                </Table.Cell>
              </Table.Row>
            )}

            {typeof payload.raw_gs_path !== 'undefined' && (
              <Table.Row>
                <Table.Cell>Raw Data</Table.Cell>
                <Table.Cell>
                  <a href={`${betaUrl}/api/jobs/${jobId}/download?stage=raw`} style={{ marginRight: '1rem' }}>
                    <Icon name="download" /> {payload.raw_gs_path.slice(payload.raw_gs_path.indexOf('input-'))}
                  </a>
                  <Label>
                    {payload.raw_num_rows} <Label.Detail>Rows</Label.Detail>
                  </Label>
                </Table.Cell>
              </Table.Row>
            )}

            {typeof payload.blacklisted_gs_path !== 'undefined' && (
              <Table.Row>
                <Table.Cell>Blacklisted Data</Table.Cell>
                <Table.Cell>
                  <a href={`${betaUrl}/api/jobs/${jobId}/download?stage=blacklisted`} style={{ marginRight: '1rem' }}>
                    <Icon name="download" />{' '}
                    {payload.blacklisted_gs_path.slice(payload.blacklisted_gs_path.indexOf('output-'))}
                  </a>
                  <Label>
                    {payload.blacklisted_num_rows} <Label.Detail>Rows</Label.Detail>
                  </Label>
                </Table.Cell>
              </Table.Row>
            )}

            {typeof payload.good_gs_path !== 'undefined' && (
              <Table.Row>
                <Table.Cell>Good Data</Table.Cell>
                <Table.Cell>
                  <a href={`${betaUrl}/api/jobs/${jobId}/download?stage=good`} style={{ marginRight: '1rem' }}>
                    <Icon name="download" /> {payload.good_gs_path.slice(payload.good_gs_path.indexOf('output-'))}
                  </a>
                  <Label>
                    {payload.good_num_rows} <Label.Detail>Rows</Label.Detail>
                  </Label>
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </Modal.Content>
    </Modal>
  );
};

export default ScrubDetailsModal;
