import { formatDistanceToNow, isAfter } from 'date-fns';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Label, Table } from 'semantic-ui-react';

import { useListUserInvitesQuery } from 'src/api/admin/user-invites';
import { RenderProps } from 'src/components/PaginatedTable';
import DeleteUserInvite from './DeleteUserInvite';

const { REACT_APP_CLIENT_URL: betaUrl } = process.env;

const AdminUserInvitesListBody = ({ limit, currentPage, setPageCount }: RenderProps) => {
  const { data: invites } = useListUserInvitesQuery({ limit, offset: limit * (currentPage - 1) });

  useEffect(() => {
    let count = 1;
    if (typeof invites !== 'undefined') {
      count = Math.ceil(invites.total / limit);
    }
    setPageCount(count);
  }, [invites, limit, setPageCount]);

  return (
    <>
      <Helmet>
        <title>User Invites - Admin | bender</title>
      </Helmet>

      {invites?.data.map(invite => {
        const now = new Date();
        const expiresAt = new Date(invite.expiresAt);

        const expiration = isAfter(expiresAt, now) ? (
          <Label>{formatDistanceToNow(expiresAt)}</Label>
        ) : (
          <Label color="red">Expired</Label>
        );

        const inviteUrl = `${betaUrl}/#/auth/accept-invite/${invite.token}`;

        return (
          <Table.Row key={invite.id}>
            <Table.Cell collapsing>{expiration}</Table.Cell>
            <Table.Cell>
              <strong>{invite.email}</strong>
            </Table.Cell>
            <Table.Cell>
              <Label color={invite.role === 'admin' ? 'blue' : undefined}>{invite.role}</Label>
            </Table.Cell>
            <Table.Cell>
              <a href={inviteUrl} target="_blank" rel="noreferrer">
                {inviteUrl}
              </a>
            </Table.Cell>
            <Table.Cell collapsing>
              <DeleteUserInvite id={invite.id} />
            </Table.Cell>
          </Table.Row>
        );
      })}
    </>
  );
};

export default AdminUserInvitesListBody;
