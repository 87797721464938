export type AdminUserInvite = UserInvite & {
  id: string;
  role: string;
  createdAt: string;
  createdById: string;
  createdBy: User | null;
};

export const Delimiters = ['Comma', 'Pipe', 'Tab'] as const;

export type Delimiter = typeof Delimiters[number];

export type PaginatedResponse<T> = {
  offset: number;
  limit: number;
  count: number;
  total: number;
  data: T[];
};

export const SortDirections = ['ASC', 'DESC'] as const;

export type SortDirection = typeof SortDirections[number];

export const SortDirectionNames = ['Ascending', 'Descending'] as const;

export const ReportingTimeRanges = [
  'today',
  'yesterday',
  'this week',
  'last week',
  'this month',
  'last month',
  'previous month',
  'last 90 days',
  'this year',
  'last year',
  'custom',
] as const;

export type ReportingTimeRange = typeof ReportingTimeRanges[number];

export const UserPermissions = ['GENERATE_NAMES', 'SCRUB_DATA'] as const;

export const DefaultUserPermissions: readonly UserPermission[] = ['GENERATE_NAMES', 'SCRUB_DATA'] as const;

export type UserPermission = typeof UserPermissions[number];

export const UserRoles = ['admin', 'user'] as const;

export type UserRole = typeof UserRoles[number];

export type User = {
  id: string;
  email: string;
  role: UserRole;
  permissions: UserPermission[];
  firstname?: string;
  lastname?: string;
  enabled: boolean;
  lastLogin: UserLogin | null;
  // logins: UserLogin[] | null;
};

export type UserInvite = {
  email: string;
  expiresAt: string;
  token: string;
};

export type UserLogin = {
  id: string;
  userId: string;
  user: User | null;
  createdAt: string;
  ipAddress: string;
};

export enum Weekday {
  'Sunday' = 0,
  'Monday' = 1,
  'Tuesday' = 2,
  'Wednesday' = 3,
  'Thursday' = 4,
  'Friday' = 5,
  'Saturday' = 6,
}

export const WeekdayLongNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'] as const;

export type WeekdayLongName = typeof WeekdayLongNames[number];

export const WeekdayShortNames = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'] as const;

export type WeekdayShortName = typeof WeekdayShortNames[number];

export enum JobType {
  'ScrubData' = 1,
  'GenerateNames' = 2,
}

export type Job = {
  id: string;
  userId: string;
  user: User | null;
  objectId?: string;
  stage: number; // 1, 2 or 3
  status: JobStatus;
  queued: string | null; // YYYY-MM-DD HH:ii:ss
  started: string | null; // YYYY-MM-DD HH:ii:ss
  stopped: string | null; // YYYY-MM-DD HH:ii:ss
  heartbeat: string | null; // YYYY-MM-DD HH:ii:ss
  progress1: number;
  progress2: number;
  progress3: number;
  payload: JobPayload;
  type: JobType;
};

export type JobPayload = {
  error?: string;
  message?: string;
};

export enum JobStatus {
  'Pending' = 1,
  'Processing' = 2,
  'Ready' = 3,
  'Stopping' = 4,
  'Error' = 5,
  'Completed' = 6,
  'Paused' = 7,
  'Cancelled' = 8,
  'Deleted' = 9,
  'Processing Externally' = 10,
  'Requires Payment' = 11,
  'Preprocessing' = 12,
}

export type ScrubDataJob = Job & {
  payload: ScrubDataJobPayload;
};

export type ScrubDataJobPayload = JobPayload & {
  sql?: string;
  ext?: string;
  delimiter?: string;
  field_mappings?: string[];
  raw_num_rows?: number;
  raw_gs_path?: string;
  blacklisted_gs_path?: string;
  blacklisted_num_rows?: number;
  good_gs_path?: string;
  good_num_rows?: number;
};

export type GenerateNamesJob = Job & {
  payload: GenerateNamesJobPayload;
};

export type GenerateNamesJobPayload = JobPayload & {
  ext?: string;
  new_columns?: string;
  raw_num_rows?: number;
  raw_gs_path?: string;
  generated_num_rows?: number;
  generated_gs_path?: string;
};
