import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Message, Segment } from 'semantic-ui-react';

import Breadcrumbs from 'src/components/Breadcrumbs';
import ConsoleLayout from 'src/components/ConsoleLayout';
import Loading from 'src/components/Loading';
import { PageContainer, PageHeader } from 'src/styles';

// TODO: Placeholder Page
const AdminUsersEdit = () => {
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setTimeout(() => setLoading(false), 250);
  }, []);

  return (
    <ConsoleLayout hideMenubar>
      <PageContainer>
        <Helmet>
          <title>Edit - Users - Admin | bender</title>
        </Helmet>

        <PageHeader>
          <Breadcrumbs crumbs={['Admin', 'Users', 'Edit']} />
        </PageHeader>

        {loading ? (
          <Loading />
        ) : (
          <Segment>
            <Message warning>
              <Message.Header>🚧 Under Construction</Message.Header>
              <Message.Content>This page isn't ready yet. Check back soon.</Message.Content>
            </Message>
          </Segment>
        )}
      </PageContainer>
    </ConsoleLayout>
  );
};

export default AdminUsersEdit;
