import { parse } from 'papaparse';
import { useCallback, useState } from 'react';
import { Button, Form, Input, Message, Segment } from 'semantic-ui-react';

import { useCustomizeMutation } from 'src/api/admin/customize';
import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';

const defaultColumns = ['First Name', 'Last Name']; // name of the columns we should use

const GenerateNamesForm = () => {
  const [file, setFile] = useState<File | null>(null);
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const [columns, setColumns] = useState<string[]>([]);
  const [newColumnNames, setNewColumnNames] = useState<{ [key: string]: string }>({});
  const [error, setError] = useState<string | null>(null);

  const { mutate: uploadCsv, isLoading } = useCustomizeMutation();

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setFile(file);
      parse(file, {
        header: true,
        preview: 1,
        step: (results, parser) => {
          const cols = results.meta.fields || [];
          setColumns(cols);
          const initialNewColumnNames = defaultColumns.reduce((acc, col) => {
            acc[col] = col;
            return acc;
          }, {} as { [key: string]: string });
          setNewColumnNames(initialNewColumnNames);
          validateColumns(cols);
          parser.abort();
        },
      });
    }
  };

  const validateColumns = (cols: string[]) => {
    const duplicateNames = defaultColumns.filter(col => cols.includes(col));
    if (duplicateNames.length > 0) {
      setError(`The following default column names already exist in the file: ${duplicateNames.join(', ')}`);
    } else {
      setError(null);
    }
  };

  const handleColumnNameChange = (column: string, newName: string) => {
    setNewColumnNames(prev => {
      const updatedNames = { ...prev, [column]: newName };
      validateNewColumnNames(updatedNames);
      return updatedNames;
    });
  };

  const validateNewColumnNames = (newNames: { [key: string]: string }) => {
    const allChanged = defaultColumns.every(col => newNames[col] && newNames[col] !== col);
    const hasEmptyNames = Object.values(newNames).some(name => name.trim() === '');
    if (allChanged && !hasEmptyNames) {
      setError(null);
    }
  };

  const onSubmit = useCallback(async () => {
    setApiMessage(undefined);

    if (!file || !!error) {
      return;
    }

    try {
      uploadCsv(
        { file, newColumnNames },
        {
          onSuccess: async resp => {
            setApiMessage({
              success: true,
              status: 200,
              message: resp.data.message,
            });
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          },
          onError: e => {
            apiErrorHandler(e, setApiMessage);
          },
        }
      );
    } catch (e: any) {
      apiErrorHandler(e, setApiMessage);
    }
  }, [uploadCsv, file, newColumnNames, error]);

  return (
    <Segment>
      <Form onSubmit={onSubmit}>
        <Form.Field>
          <label>Upload CSV File</label>
          <Input type="file" accept=".csv" onChange={handleFileChange} />
        </Form.Field>
        {columns.length > 0 && (
          <>
            {columns
              .filter(column => defaultColumns.includes(column))
              .map(column => (
                <Form.Field key={column}>
                  <label>{column}</label>
                  <Input
                    value={newColumnNames[column] || ''}
                    onChange={e => handleColumnNameChange(column, e.target.value)}
                  />
                </Form.Field>
              ))}
            {error && <Message negative>{error}</Message>}
            <ApiMessage data={apiMessage} />
            <Button type="submit" color="blue" disabled={!!error} loading={isLoading}>
              Submit
            </Button>
          </>
        )}
      </Form>
    </Segment>
  );
};

export default GenerateNamesForm;
