import { Link, NavLink, useLocation } from 'react-router-dom';

import { useGetUserProfileQuery } from 'src/api/auth';
import { HeaderContainer, HeaderMenu, HeaderMenuItem, Logo } from './styles';
import UserDropdown from './UserDropdown';

const Header = () => {
  const { pathname } = useLocation();
  const { data } = useGetUserProfileQuery();

  const isAdmin = data?.user && data.user.role === 'admin';
  const inAdmin = isAdmin && pathname.startsWith('/admin');

  return (
    <HeaderContainer admin={inAdmin}>
      <HeaderMenu>
        <Logo admin={inAdmin}>
          <Link to="/">bender</Link>
        </Logo>

        {isAdmin && (
          <HeaderMenuItem admin={inAdmin}>
            <Link to={`/admin`} className="nav-item">
              Admin
            </Link>
            {inAdmin && (
              <>
                <NavLink to={`/admin/users`} className="nav-item">
                  Users
                </NavLink>
              </>
            )}
          </HeaderMenuItem>
        )}
      </HeaderMenu>

      <HeaderMenu>
        <UserDropdown />
      </HeaderMenu>
    </HeaderContainer>
  );
};

export default Header;
