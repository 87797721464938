import { isEmpty } from 'lodash';
import { useCallback, useState } from 'react';
import { Button, Form, Icon, Modal } from 'semantic-ui-react';

import { InviteUserInput, useInviteUserMutation } from 'src/api/admin/user-invites';
import { apiErrorHandler, ApiMessageData, APIValidationError } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';
import { DefaultUserPermissions, UserPermissions } from 'src/types';

type ValidationErrors = {
  email?: string;
  role?: string;
  permissions?: string;
};

const RoleOptions = [
  { key: 'admin', value: 'admin', text: 'Admin' },
  { key: 'user', value: 'user', text: 'User' },
];

const getInitialFormdata = (): InviteUserInput => ({
  email: '',
  role: '',
  permissions: DefaultUserPermissions.map(v => v),
});

const InviteUser = () => {
  const [open, setOpen] = useState(false);
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [formdata, setFormdata] = useState<InviteUserInput>(() => getInitialFormdata());
  const { isLoading, mutateAsync } = useInviteUserMutation();

  const onChange = useCallback((_, { name, value }) => {
    setFormdata(prev => ({ ...prev, [name]: value }));
  }, []);

  const onClose = useCallback(() => {
    setApiMessage(undefined);
    setFormdata(getInitialFormdata());
    setErrors({});
    setOpen(false);
  }, []);

  const validate = useCallback((input: InviteUserInput) => {
    const validationErrors: ValidationErrors = {};

    if (input.email === '') {
      validationErrors.email = 'Email is required';
    }
    if (input.role === '') {
      validationErrors.role = 'Role is required';
    }

    setErrors(validationErrors);

    return validationErrors;
  }, []);

  const onSubmit = useCallback(async () => {
    if (!isEmpty(validate(formdata))) {
      return;
    }

    try {
      await mutateAsync(formdata);
      onClose();
    } catch (e: any) {
      apiErrorHandler(e, setApiMessage);

      if (e.response && e.response.data && e.response.data.errors) {
        const { errors } = e.response.data;

        const validationErrors: ValidationErrors = {};

        errors.forEach(({ field, error }: APIValidationError) => {
          switch (field.toLowerCase()) {
            case 'email':
              validationErrors.email = error;
              break;

            case 'role':
              validationErrors.role = error;
              break;
          }
        });

        setErrors(validationErrors);
      }
    }
  }, [formdata, mutateAsync, onClose, validate]);

  return (
    <Modal
      size="tiny"
      open={open}
      onClose={onClose}
      onOpen={() => setOpen(true)}
      trigger={
        <Button floated="right" color="blue">
          <Icon name="plus" />
          Invite User
        </Button>
      }
    >
      <Modal.Header>Invite User</Modal.Header>
      <Modal.Content>
        <ApiMessage data={apiMessage} />

        <Form onSubmit={onSubmit}>
          <Form.Input label="Email" error={errors.email} name="email" onChange={onChange} value={formdata.email} />
          <Form.Select
            clearable
            label="Role"
            error={errors.role}
            name="role"
            options={RoleOptions}
            onChange={onChange}
            value={formdata.role}
          />
          <Form.Select
            multiple
            clearable
            label="Permissions"
            error={errors.permissions}
            name="permissions"
            options={UserPermissions.map(v => ({ key: v, value: v, text: v }))}
            onChange={onChange}
            value={formdata.permissions}
          />

          <Button loading={isLoading} color="blue" fluid>
            Invite
          </Button>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default InviteUser;
