import { useMutation, useQueryClient } from 'react-query';

import { Delimiter, JobType } from 'src/types';
import { ApiResponse, http } from './http-common';

export type ScrubDataForm = {
  file?: File;
  delimiter: Delimiter;
  fieldMappings: string[];
};

export const useRunScrubDataMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (input: ScrubDataForm) => {
      if (!input.file) return { success: false, message: 'Missing file' };

      const formData = new FormData();
      formData.append('file', input.file);
      formData.append('delimiter', input.delimiter);
      formData.append('field_mappings', input.fieldMappings.join(','));

      const res = await http.post<ApiResponse>('/api/scrub-data/run', formData);
      return res.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['jobs', JobType.ScrubData]);
      },
    }
  );
};
