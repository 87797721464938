import 'react-datepicker/dist/react-datepicker.css';

import diff from 'lodash/difference';
import isEmpty from 'lodash/isEmpty';
import { parse } from 'papaparse';
import { ChangeEvent, SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react';
import { Button, DropdownProps, Form, FormProps, Label, Message, Segment, Table } from 'semantic-ui-react';
import stringSimilarity from 'string-similarity';

import { apiErrorHandler, ApiMessageData } from 'src/api/http-common';
import { ScrubDataForm, useRunScrubDataMutation } from 'src/api/scrub-data';
import ApiMessage from 'src/components/ApiMessage';
import { DelimiterOptions, FileDelimiters } from 'src/config';
import { Delimiter } from 'src/types';

type ValidationErrors = {
  file?: string;
  field_mappings?: string;
  response?: string;
};

const RequiredFields = ['first_name', 'last_name', 'zip'];

// For a better string matching, add an alias for fields
const aliasMapping: { [key: string]: string[] } = {
  zip: ['zip', 'postal_code'],
};

const FieldMappingOptions = [
  { key: '__skip__', text: 'Do Not Import', value: '__skip__' },
  { key: '__keep__', text: 'Keep As-Is', value: '__keep__' },
  ...RequiredFields.map(f => ({
    key: f,
    text: f,
    value: f,
  })),
];

const getInitialFormdata = (): ScrubDataForm => ({
  file: undefined,
  delimiter: 'Comma',
  fieldMappings: [],
});

const ScrubForm = () => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [formdata, setFormdata] = useState<ScrubDataForm>(() => getInitialFormdata());
  const [filePreview, setFilePreview] = useState<string[][] | null>(null);
  const [rowCounts, setRowCounts] = useState<{ total: number; qualified: number }>();
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const { mutateAsync: uploadCsv, isLoading } = useRunScrubDataMutation();

  const validate = useCallback((input: ScrubDataForm): ValidationErrors => {
    const validationErrors: ValidationErrors = {};

    if (typeof input.file === 'undefined') {
      validationErrors.file = 'file is required';
    } else if (['text/csv', 'text/plain'].indexOf(input.file.type) === -1) {
      validationErrors.file = 'Invalid file type, please select a CSV';
    }
    const missingMappings: string[] = diff(RequiredFields, input.fieldMappings);
    if (missingMappings.length > 0) {
      validationErrors.field_mappings = missingMappings.join(', ');
    }

    setErrors(validationErrors);

    return validationErrors;
  }, []);

  useEffect(() => {
    if (typeof formdata.file === 'undefined') {
      setFilePreview(null);
      return;
    }
    if (typeof errors.file !== 'undefined') {
      setFilePreview(null);
      return;
    }

    const d = FileDelimiters.has(formdata.delimiter) ? FileDelimiters.get(formdata.delimiter) : ',';

    const parsedRows: string[][] = [];
    parse<string[]>(formdata.file, {
      delimiter: d,
      step: ({ data }, parser) => {
        parsedRows.push(data);
        if (parsedRows.length >= 5) {
          parser.abort();
          return;
        }
      },
      complete: () => {
        setFilePreview(parsedRows);

        setFormdata(prev => {
          const fieldMappingValues = FieldMappingOptions.map(fm => fm.value);
          const headers = parsedRows[0];
          const fm = Array(headers.length).fill('__keep__');
          for (const f of fieldMappingValues) {
            if (f === '__skip__' || f === '__keep__') continue;
            let bestScore = 0;
            let bestIdx = 0;
            headers.forEach((h, i) => {
              if (fm[i] !== '__skip__' && fm[i] !== '__keep__' && fm[i] === f) return;
              const fields = aliasMapping[f.toLowerCase()] || [f.toLowerCase()];
              for (const field of fields) {
                const ratio = stringSimilarity.compareTwoStrings(h.toLowerCase(), field);
                if (ratio >= bestScore) {
                  bestScore = ratio;
                  bestIdx = i;
                }
              }
            });
            if (bestScore >= 0.5) {
              fm[bestIdx] = f;
            }
          }
          const next: ScrubDataForm = {
            ...prev,
            fieldMappings: fm,
          };
          validate(next);
          return next;
        });
      },
    });
  }, [errors.file, formdata.delimiter, formdata.file, validate]);

  useEffect(() => {
    if (typeof formdata.file === 'undefined') {
      setRowCounts(undefined);
      return;
    }
    if (typeof errors.file !== 'undefined') {
      setRowCounts(undefined);
      return;
    }

    const idx: { [k: string]: number } = {};

    RequiredFields.forEach(f => {
      idx[f] = formdata.fieldMappings.indexOf(f);
    });

    const d = FileDelimiters.has(formdata.delimiter) ? FileDelimiters.get(formdata.delimiter) : ',';

    let total = 0;
    let qualified = 0;
    parse<string[]>(formdata.file, {
      delimiter: d,
      step: ({ data }) => {
        total++;
        for (let i = 0; i < RequiredFields.length; i++) {
          const f = RequiredFields[i];
          if (idx[f] === -1 || !data[idx[f]]) return;
        }
        if (RequiredFields.includes('zip') && idx.zip) {
          const zip = (data[idx.zip] || '').replace(/\D/g, '');
          if (zip.length === 0 || zip === '0') return;
        }
        qualified++;
      },
      complete: () => {
        setRowCounts({ total, qualified });
      },
    });
  }, [errors.file, formdata.delimiter, formdata.file, formdata.fieldMappings]);

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setFormdata(prev => {
        let file;
        if (event.target.files && event.target.files.length) {
          file = event.target.files[0];
        }

        const next: ScrubDataForm = { ...prev, file };
        validate(next);
        return next;
      });
    },
    [validate]
  );

  const onChangeDelimiter = useCallback(
    (_event: SyntheticEvent<HTMLElement, Event>, { value }: DropdownProps) => {
      setFormdata({ ...formdata, delimiter: value as Delimiter });
    },
    [formdata]
  );

  const onChangeFieldMapping = useCallback(
    (i: number) =>
      (_event: SyntheticEvent<HTMLElement, Event>, { value }: DropdownProps) => {
        setFormdata(prev => {
          const newMappings = [...prev.fieldMappings];
          newMappings[i] = `${value}`;
          const next: ScrubDataForm = { ...prev, fieldMappings: newMappings };
          validate(next);
          return next;
        });
      },
    [validate]
  );

  const cleanupForm = () => {
    // set default values
    setFormdata(getInitialFormdata());
    setFilePreview(null);
    setRowCounts(undefined);
    if (fileInputRef.current !== null) {
      fileInputRef.current.value = '';
    }
  };

  const onSubmit = useCallback(
    async (_event: React.FormEvent<HTMLFormElement>, _data: FormProps) => {
      setApiMessage(undefined);
      if (!isEmpty(validate(formdata))) {
        return;
      }

      // send the form to create the job
      try {
        await uploadCsv(formdata);
        cleanupForm();
      } catch (e: any) {
        apiErrorHandler(e, setApiMessage);
      }
    },
    [uploadCsv, formdata, validate]
  );

  const ignoreAllKept = useCallback(() => {
    setFormdata(prev => {
      const next: ScrubDataForm = {
        ...prev,
        fieldMappings: prev.fieldMappings.map(fm => {
          if (fm !== '__keep__') return fm;
          return '__skip__';
        }),
      };
      validate(next);
      return next;
    });
  }, [validate]);

  const keepAllIgnored = useCallback(() => {
    setFormdata(prev => {
      const next: ScrubDataForm = {
        ...prev,
        fieldMappings: prev.fieldMappings.map(fm => {
          if (fm !== '__skip__') return fm;
          return '__keep__';
        }),
      };
      validate(next);
      return next;
    });
  }, [validate]);

  return (
    <Segment>
      {typeof rowCounts !== 'undefined' && (
        <Message success visible>
          <Message.Header>File Details</Message.Header>
          <Message.Content>
            Your file contains <strong>{rowCounts.qualified}</strong> qualified records out of{' '}
            <strong>{rowCounts.total}</strong> total rows.
          </Message.Content>
        </Message>
      )}

      {errors.field_mappings && (
        <Message error visible>
          <Message.Header>🚨 Missing required field mappings:</Message.Header>
          <Message.Content>{errors.field_mappings}</Message.Content>
        </Message>
      )}
      <ApiMessage data={apiMessage} />
      <Form onSubmit={onSubmit}>
        <Form.Group widths="equal">
          <Form.Field error={'file' in errors}>
            <label>File</label>
            <input type="file" name="file" onChange={onChange} ref={fileInputRef} />
            {errors.file && (
              <Label pointing prompt>
                {errors.file}
              </Label>
            )}
          </Form.Field>

          <Form.Select
            label="Delimiter"
            value={formdata.delimiter}
            options={DelimiterOptions}
            name="delimiter"
            onChange={onChangeDelimiter}
          />
        </Form.Group>

        {filePreview && (
          <>
            <div style={{ marginBottom: '1rem' }}>
              <Button type="button" content={`Ignore All "Kept As-Is"`} onClick={ignoreAllKept} />
              <Button type="button" content={`Keep All "Do Not Import"`} onClick={keepAllIgnored} />
            </div>

            <div style={{ overflowX: 'scroll', marginBottom: '1rem' }}>
              <Table>
                <Table.Header>
                  <Table.Row>
                    {filePreview[0].map((_cell: string, i: number) => (
                      <Table.HeaderCell key={i}>
                        <Form.Select
                          upward={false}
                          options={FieldMappingOptions.filter(
                            o =>
                              o.value === '__skip__' ||
                              o.value === '__keep__' ||
                              o.value === formdata.fieldMappings[i] ||
                              formdata.fieldMappings.indexOf(o.value) === -1
                          )}
                          value={formdata.fieldMappings[i]}
                          onChange={onChangeFieldMapping(i)}
                          style={
                            formdata.fieldMappings[i] === '__skip__'
                              ? { background: 'transparent', color: 'rgba(0,0,0,0.25)' }
                              : formdata.fieldMappings[i] === '__keep__'
                              ? undefined
                              : {
                                  borderColor: 'rgba(44, 102, 45, 0.5)',
                                  background: '#fcfff5',
                                  color: '#2c662d',
                                }
                          }
                        />
                      </Table.HeaderCell>
                    ))}
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {filePreview
                    .filter((r: string[]) => r && r.length === filePreview[0].length)
                    .map((row: string[], rowIdx: number) => (
                      <Table.Row key={rowIdx}>
                        {row.map((cell: string, cellIdx: number) => (
                          <Table.Cell key={`${rowIdx}-${cellIdx}`}>{cell}</Table.Cell>
                        ))}
                      </Table.Row>
                    ))}
                </Table.Body>
              </Table>
            </div>
          </>
        )}

        <Button content="Upload" color="blue" loading={isLoading} />
      </Form>
    </Segment>
  );
};

export default ScrubForm;
