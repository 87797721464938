import styled, { css } from 'styled-components';

import theme from 'src/styles/theme';

export const HeaderContainer = styled.header<{ admin?: boolean }>`
  grid-area: h;
  background-color: ${p => (p.admin ? '#191919' : '#fff')};
  color: ${p => (p.admin ? 'white' : undefined)};
  display: flex;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 10%);
  z-index: 5000;
  justify-content: space-between;
`;

export const HeaderMenu = styled.div`
  padding: 0 1rem;
  display: flex;
  justify-content: flex-end;
`;

export const HeaderMenuItem = styled.div<{ admin?: boolean }>`
  display: flex;
  ${p =>
    p.admin &&
    css`
      border-left: 1px solid rgba(255, 255, 255, 0.15);
    `}

  a,
  button,
  .ui.dropdown {
    background: transparent;
    border: none;
    color: ${p => (p.admin ? 'white' : 'black')};
    cursor: pointer;

    ${p =>
      p.admin &&
      css`
        border-right: 1px solid rgba(255, 255, 255, 0.15);
      `}

    &:hover, &.active {
      background: ${p => (p.admin ? '#333' : '#fafafa')};
      color: ${p => (p.admin ? 'white' : p.theme.primaryHover)};
    }
  }

  a.nav-item {
    display: block;
    padding: 1rem;
  }

  .ui.dropdown {
    flex: 1;
    display: block;
    padding: 1rem;
  }
`;

export const Logo = styled.div<{ admin?: boolean }>`
  display: flex;
  align-items: center;
  padding: 0 1rem 0 0;

  a {
    font-weight: bold;
    font-size: 1.4rem;
    color: ${p => (p.admin ? 'white' : theme.black)};
  }

  img,
  svg {
    display: block;
    height: 20px;
    width: auto;
  }
`;
