import { MouseEvent, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'semantic-ui-react';
import styled from 'styled-components';

import { useGetUserProfileQuery, useLogoutMutation } from 'src/api/auth';
import theme from 'src/styles/theme';

export const ProfileMenuItem = styled.div`
  display: flex;
  padding-left: 1rem;

  a,
  button,
  .ui.dropdown {
    background: transparent;
    border: none;
    color: black;
    cursor: pointer;

    :hover {
      /* background: #fafafa; */
      /* color: ${p => p.theme.primaryHover}; */
    }
  }

  .ui.dropdown {
    flex: 1;
    display: block;
    padding: 1rem;
  }
`;

const ProfileButton = styled.div`
  &:before {
    content: '';
    display: block;
    background: ${theme.primary};
    width: 30px;
    height: 30px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 100%;
  }

  &:hover:before {
    background: ${theme.primaryHover};
  }

  span {
    position: relative;
    z-index: 1;
    color: white;
    display: inline-block;
    text-transform: uppercase;
  }
`;

const UserDropdown = () => {
  const { data } = useGetUserProfileQuery();
  const { mutateAsync: logout } = useLogoutMutation();

  const onClickLogout = useCallback(
    async (event: MouseEvent<HTMLDivElement>) => {
      event.preventDefault();
      try {
        await logout();
      } catch (e: any) {}
    },
    [logout]
  );

  if (!data?.user) return null;

  return (
    <ProfileMenuItem>
      <Dropdown
        trigger={
          <ProfileButton>
            <span>{data.user.email[0]}</span>
          </ProfileButton>
        }
        icon={null}
        direction="left"
      >
        <Dropdown.Menu>
          <Dropdown.Item disabled>
            <span>
              Signed in as{' '}
              <strong>
                {data.user.firstname} {data.user.lastname}
              </strong>
            </span>
          </Dropdown.Item>

          <Link to={`/user/settings`} className="item">
            Profile
          </Link>

          <Dropdown.Divider />

          <Dropdown.Item onClick={onClickLogout}>Logout</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </ProfileMenuItem>
  );
};

export default UserDropdown;
