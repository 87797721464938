import { formatDistanceToNow } from 'date-fns';
import { noop } from 'lodash';
import { createElement, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Icon, Label, Popup, Progress, Table } from 'semantic-ui-react';

import { useGetJobsQuery } from 'src/api/jobs';
import DeleteJob from 'src/components/JobHistory/DeleteJob';
import StopJob from 'src/components/JobHistory/StopJob';
import { RenderProps } from 'src/components/PaginatedTable';
import { Row } from 'src/styles';
import { JobStatus, JobType } from 'src/types';
import { getJobStatusColor } from 'src/utils';
import DuplicateJob from './DuplicateJob';

type Props = RenderProps & {
  details?: (j: any) => JSX.Element;
  jobType?: JobType;
};

const JobsListBody = ({ limit, currentPage, setPageCount, details, jobType }: Props) => {
  const { id } = useParams<{ id: string }>();
  const { data: jobs } = useGetJobsQuery({
    limit,
    offset: (currentPage - 1) * limit,
    type: jobType,
    objectId: id,
  });

  useEffect(() => {
    if (!jobs?.total) return;
    setPageCount(Math.ceil(jobs.total / limit));
  }, [setPageCount, jobs?.total, limit]);

  if (typeof jobs === 'undefined') return null;

  return (
    <>
      {jobs.data.map(job => (
        <Table.Row key={job.id}>
          <Table.Cell collapsing>{job.id}</Table.Cell>
          <Table.Cell>
            {job.queued && formatDistanceToNow(new Date(job.queued), { addSuffix: true, includeSeconds: true })}
          </Table.Cell>
          <Table.Cell>
            {job.status === JobStatus.Error && job.payload.error ? (
              <Popup
                trigger={
                  <Label color={getJobStatusColor(job.status)}>
                    {JobStatus[job.status]}
                    <Icon name="info circle" style={{ marginRight: 0, marginLeft: '0.5rem' }} />
                  </Label>
                }
              >
                {job.payload.error}
              </Popup>
            ) : job.status === JobStatus.Processing || job.status === JobStatus['Processing Externally'] ? (
              <Progress
                indicating
                percent={job.stage === 1 ? job.progress1 * 100 : job.stage === 2 ? job.progress2 * 100 : 0}
                content={job.payload.message || `Stage ${job.stage}`}
              />
            ) : (
              <Label color={getJobStatusColor(job.status)}>{JobStatus[job.status]}</Label>
            )}
          </Table.Cell>
          <Table.Cell>
            {job.started && formatDistanceToNow(new Date(job.started), { addSuffix: true, includeSeconds: true })}
          </Table.Cell>
          <Table.Cell>
            {job.heartbeat && formatDistanceToNow(new Date(job.heartbeat), { addSuffix: true, includeSeconds: true })}
          </Table.Cell>
          <Table.Cell collapsing>
            <Row style={{ alignItems: 'center' }}>
              {/* @ts-ignore */}
              {typeof details !== 'undefined' && createElement(details, job)}
              <DuplicateJob id={job.id} />
              {job.status === JobStatus.Processing ||
                (job.status === JobStatus['Processing Externally'] && <StopJob id={job.id} onClose={noop} />)}
              {job.status !== JobStatus.Completed && job.status !== JobStatus.Cancelled && (
                <DeleteJob id={job.id} onClose={noop} />
              )}
            </Row>
          </Table.Cell>
        </Table.Row>
      ))}
    </>
  );
};

export default JobsListBody;
