import { useMutation } from 'react-query';

import { ApiResponse, http } from '../http-common';

interface UploadCsv {
  file: File;
  newColumnNames: { [key: string]: string };
}

export const useCustomizeMutation = () => {
  return useMutation(async (input: UploadCsv) => {
    const formData = new FormData();
    formData.append('file', input.file);
    formData.append('newColumnNames', JSON.stringify(input.newColumnNames));
    const res = await http.post<ApiResponse>('/api/generate-names', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return res;
  });
};
