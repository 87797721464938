import { useMemo } from 'react';
import { Segment } from 'semantic-ui-react';

import { HorizontalDivider, PageHeader } from 'src/styles';
import { JobType } from 'src/types';
import PaginatedTable from '../PaginatedTable';
import JobsListBody from './JobsListBody';

const HEADERS = ['ID', 'Created At', 'Job Status', 'Started at', 'Last Updated', ''];

type Props = {
  details?: (j: any) => JSX.Element;
  jobType?: JobType;
  qsParam?: string | boolean;
};

const JobHistory = ({ details, jobType, qsParam = 'page' }: Props) => {
  const headers = useMemo(() => {
    const h = [...HEADERS];
    return h;
  }, []);

  return (
    <Segment>
      <PageHeader style={{ marginBottom: 0 }}>Job History</PageHeader>

      <HorizontalDivider style={{ margin: '1rem -1rem' }} />

      <PaginatedTable
        headers={headers}
        renderBody={props => <JobsListBody {...props} details={details} jobType={jobType} />}
        qsParam={qsParam}
      />
    </Segment>
  );
};

export default JobHistory;
