import { Helmet } from 'react-helmet-async';

import Breadcrumbs from 'src/components/Breadcrumbs';
import ConsoleLayout from 'src/components/ConsoleLayout';
import JobHistory from 'src/components/JobHistory';
import { PageContainer, PageHeader } from 'src/styles';
import { JobType } from 'src/types';
import GenerateNamesDetailsModal from './GenerateNamesDetailsModal';
import GenerateNamesForm from './GenerateNamesForm';

const GenerateNames = () => {
  return (
    <ConsoleLayout>
      <PageContainer>
        <Helmet>
          <title>Generate Names | bender</title>
        </Helmet>

        <PageHeader style={{ marginBottom: 0 }}>
          <Breadcrumbs crumbs={['Generate Names']} />
        </PageHeader>

        <GenerateNamesForm />
        <JobHistory details={GenerateNamesDetailsModal} jobType={JobType.GenerateNames} />
      </PageContainer>
    </ConsoleLayout>
  );
};

export default GenerateNames;
