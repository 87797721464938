import { Redirect, Route, Switch } from 'react-router-dom';

import AdminRoute from './AdminRoute';
import AdminUsersEdit from './pages/admin/users/edit';
import AdminUsersList from './pages/admin/users/list';
import AuthAcceptInvite from './pages/auth/accept-invite';
import AuthForgotPassword from './pages/auth/forgot-password';
import AuthLogin from './pages/auth/login';
import AuthResetPassword from './pages/auth/reset-password';
import UserSettings from './pages/console/user/settings';
import GenerateNames from './pages/generate-names';
import NotFound from './pages/not-found';
import ScrubData from './pages/scrub-data';
import RestrictedRoute from './RestrictedRoute';

const Routes = () => (
  <Switch>
    {/* Auth */}
    <Route exact path="/auth/accept-invite/:token" component={AuthAcceptInvite} />
    <Route exact path="/auth/forgot-password" component={AuthForgotPassword} />
    <Route exact path="/auth/reset-password/:token" component={AuthResetPassword} />
    <Route exact path="/auth/login" component={AuthLogin} />

    {/* Admin */}
    <AdminRoute exact path="/admin/users/:id" component={AdminUsersEdit} />
    <AdminRoute exact path="/admin/users" component={AdminUsersList} />
    <Redirect exact path="/admin" to="/admin/users" />

    {/* Console */}
    <Route exact path="/user/settings" component={UserSettings} />

    <RestrictedRoute exact path="/scrub-data" component={ScrubData} permission="SCRUB_DATA" />
    <RestrictedRoute exact path="/generate-names" component={GenerateNames} permission="GENERATE_NAMES" />
    <Redirect exact path="/" to="/generate-names" />

    <Route path="*" component={NotFound} />
  </Switch>
);

export default Routes;
