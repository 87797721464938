import { Helmet } from 'react-helmet-async';

import Breadcrumbs from 'src/components/Breadcrumbs';
import ConsoleLayout from 'src/components/ConsoleLayout';
import JobHistory from 'src/components/JobHistory';
import { PageContainer, PageHeader } from 'src/styles';
import { JobType } from 'src/types';
import ScrubDetailsModal from './ScrubDetailsModal';
import ScrubForm from './ScrubForm';

const ScrubData = () => {
  return (
    <ConsoleLayout>
      <PageContainer>
        <Helmet>
          <title>Scrub Data | bender</title>
        </Helmet>

        <PageHeader style={{ marginBottom: 0 }}>
          <Breadcrumbs crumbs={['Scrub Data']} />
        </PageHeader>

        <ScrubForm />
        <JobHistory details={ScrubDetailsModal} jobType={JobType.ScrubData} />
      </PageContainer>
    </ConsoleLayout>
  );
};

export default ScrubData;
