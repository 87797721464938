import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Icon } from 'semantic-ui-react';

import { ListUsersFilters } from 'src/api/admin/users';
import PaginatedTable, { RenderProps } from 'src/components/PaginatedTable';
import useSearchQuery from 'src/hooks/useSearchQuery';
import AdminUsersListBody from './AdminUsersListBody';

export const STATUS_FILTERS = [
  { key: 'enabled', value: true, text: 'Enabled' },
  { key: 'disabled', value: false, text: 'Disabled' },
];

const getFiltersFromQuery = (query: URLSearchParams): ListUsersFilters => {
  let enabled;
  switch (query.get('enabled')) {
    case '1':
      enabled = true;
      break;

    case '0':
      enabled = false;
      break;

    default:
      enabled = undefined;
      break;
  }

  const search = query.get('search') || undefined;

  return { enabled, search };
};

const getSearchFromQuery = (query: URLSearchParams): string => {
  return query.get('search') || '';
};

const AdminUsersListPane = () => {
  const { push, replace } = useHistory();
  const query = useSearchQuery();
  const [filters, setFilters] = useState<ListUsersFilters>(() => getFiltersFromQuery(query));
  const [searchQuery, setSearchQuery] = useState<string>(() => getSearchFromQuery(query));

  useEffect(() => {
    if (!query.has('enabled')) {
      query.set('enabled', '1');
      replace({ search: query.toString() });
    }
  });

  useEffect(() => {
    setFilters(getFiltersFromQuery(query));
  }, [query]);

  const onSubmit = useCallback(() => {
    query.set('search', searchQuery);
    query.set('page', '1');
    push({ search: query.toString() });
    // NOTE: the useEffect hook above (watching query) will set the filter state for us
  }, [push, query, searchQuery]);

  return (
    <>
      <Form onSubmit={onSubmit}>
        <Form.Group>
          <Form.Dropdown
            label="Status"
            placeholder="All"
            selection
            clearable
            options={STATUS_FILTERS}
            value={typeof filters.enabled === 'undefined' ? '' : filters.enabled}
            onChange={(_, { value }) => {
              let enabled = '';
              switch (value) {
                case '':
                  enabled = '';
                  break;
                default:
                  enabled = Number(value).toString();
                  break;
              }

              query.set('enabled', enabled);
              query.set('page', '1');
              push({ search: query.toString() });

              // NOTE: the useEffect hook above (watching query) will set the filter state for us
            }}
          />

          <Form.Input
            label="Search"
            placeholder="by name"
            value={searchQuery || ''}
            onChange={(_, { value }) => setSearchQuery(value)}
            onBlur={onSubmit}
          />

          <Form.Button label="&nbsp;" icon>
            <Icon name="search" />
          </Form.Button>
        </Form.Group>
      </Form>

      <PaginatedTable
        headers={['Status', 'Name', 'Email', 'Role', 'Last Login', '']}
        renderBody={(props: RenderProps) => <AdminUsersListBody {...props} filters={filters} />}
      />
    </>
  );
};

export default AdminUsersListPane;
