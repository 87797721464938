import isEmpty from 'lodash/isEmpty';
import { useCallback, useState } from 'react';
import { Link, Redirect, useHistory, useLocation, useParams } from 'react-router-dom';
import { Button, Form, Header, Icon } from 'semantic-ui-react';

import { useGetUserProfileQuery, useResetPasswordMutation } from 'src/api/auth';
import { ApiMessageData, APIValidationError } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';
import theme from 'src/styles/theme';
import Circle from '../Circle';
import { Container, ContainerLeft, ContainerRight, GridCenter, Title } from '../styles';

type ValidationErrors = {
  password?: string;
  confirm?: string;
};

type Formdata = {
  password: string;
  confirm: string;
};

const AuthResetPassword = () => {
  const { data, isLoading: profileLoading } = useGetUserProfileQuery();
  const location = useLocation<{ redirect?: string }>();
  const { token } = useParams<{ token: string }>();
  const history = useHistory();
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [formdata, setFormdata] = useState<Formdata>({ password: '', confirm: '' });
  const { mutateAsync, isLoading } = useResetPasswordMutation();

  const validate = useCallback((input: Formdata): ValidationErrors => {
    const validationErrors: ValidationErrors = {};

    if (input.password === '') {
      validationErrors.password = 'Password is required';
    }
    if (input.confirm === '') {
      validationErrors.confirm = 'Confirm Passowrd is required';
    }
    if (input.password !== input.confirm) {
      validationErrors.confirm = 'Confirm Passowrd does not match password';
    }

    setErrors(validationErrors);

    return validationErrors;
  }, []);

  const onSubmit = async () => {
    setApiMessage(undefined);

    if (!isEmpty(validate(formdata))) {
      return;
    }

    try {
      await mutateAsync({
        token,
        password: formdata.password,
      });

      setApiMessage({ success: true, message: 'Password reset successfully.' });

      setTimeout(() => history.push('/auth/login'), 3000);
    } catch (e: any) {
      // apiErrorHandler(e, setApiError);

      if (e.response && e.response.data) {
        if (e.response.data.message) {
          setApiMessage({ success: false, status: e.response.status, message: e.response.data.message });
        }

        if (e.response.data.errors) {
          const { errors } = e.response.data;

          const validationErrors: ValidationErrors = {};

          errors.forEach(({ field, error }: APIValidationError) => {
            switch (field.toLowerCase()) {
              case 'password':
                validationErrors.password = error;
                break;
            }
          });

          setErrors(validationErrors);
        }
      }
    }
  };

  const onChange = useCallback((_, { name, value }) => setFormdata(prev => ({ ...prev, [name]: value })), []);

  if (!profileLoading && data?.user) return <Redirect to="/" />;

  return (
    <GridCenter>
      <Container>
        <ContainerLeft>
          <Link to={{ pathname: '/auth/login', state: location.state }}>
            <Icon name="arrow left" />
            Back
          </Link>

          <Header as="h2">Create New Password</Header>
          <p style={{ color: theme.gray }}>Your new password must be different from previous used passwords.</p>

          <ApiMessage data={apiMessage} />

          {((!apiMessage || !apiMessage.success) && (
            <Form onSubmit={onSubmit}>
              <Form.Input
                type="password"
                label="Password"
                name="password"
                value={formdata.password}
                onChange={onChange}
                error={errors.password}
              />

              <Form.Input
                type="password"
                label="Confirm Password"
                name="confirm"
                value={formdata.confirm}
                onChange={onChange}
                error={errors.confirm}
              />

              <Button fluid color="blue" loading={isLoading}>
                Reset Password
              </Button>
            </Form>
          )) || (
            <Button fluid color="blue" onClick={() => history.push('/auth/login')}>
              Proceed to Login
            </Button>
          )}
        </ContainerLeft>

        <ContainerRight>
          <Title to="/">bender</Title>
          <Circle />
        </ContainerRight>
      </Container>
    </GridCenter>
  );
};

export default AuthResetPassword;
