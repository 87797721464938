import { DropdownItemProps } from 'semantic-ui-react';

import { Delimiter } from './types';

export const FeedFields: string[] = [
  'email',
  'phone',
  'firstname',
  'lastname',
  'address1',
  'address2',
  'city',
  'state',
  'zip',
  'datetime',
  'ip',
  'sourceid',
  'dob',
];

export const FileDelimiters = new Map<Delimiter, string>();
FileDelimiters.set('Comma', ',');
FileDelimiters.set('Pipe', '|');
FileDelimiters.set('Tab', '\t');

export const DelimiterOptions: DropdownItemProps[] = Array.from(FileDelimiters.keys()).map(k => ({
  key: k,
  text: k,
  value: k,
}));

export const UseCaseOptions: DropdownItemProps[] = [
  'Identify & Verification',
  'Asset Management',
  'Lead Generation',
  'Intelligent Routing',
  'Appointment Scheduling',
  'Customer Support',
  'Self-Service',
  'Automated Support',
  'Appointment Reminders',
  'Employee Notifications',
  'Delivery Notifications',
  'Emergency Notifications',
  'Contactless Delivery',
  'Order Notifications',
  'Service Alerts',
  'Purchase Confirmation',
  'Mass Alerts',
  'Fraud Alerts',
  'Contact Tracing',
  'Lead Management',
  'Lead Nurturing',
  'Telemarketing',
  'Marketing Events',
  'Rewards Program',
  'Lead Alerts',
  'Lead Distribution',
  'Abandoned Cart',
  'Call Tracking',
  'Outbound Dialer',
  'Click to Call',
  'Phone System',
  'Meetings/Collaboration',
  'Telehealth',
  'Distance Learning',
  'Shift Management',
  'Field Notifications',
  'Dating/Social',
  'Remote appointments',
  'Group Messaging',
  'Exam Proctoring',
  'Tutoring',
  'Therapy (Individual+Group)',
  'Pharmacy',
  'First Responder',
  'Survey/Research',
].map(v => ({ key: v, text: v, value: v }));
