import isEmpty from 'lodash/isEmpty';
import { useCallback, useState } from 'react';
import { Link, Redirect, useLocation } from 'react-router-dom';
import { Button, Form, Header, Icon } from 'semantic-ui-react';

import { ForgotPasswordInput, useForgotPasswordMutation, useGetUserProfileQuery } from 'src/api/auth';
import { ApiMessageData, APIValidationError } from 'src/api/http-common';
import ApiMessage from 'src/components/ApiMessage';
import theme from 'src/styles/theme';
import Circle from '../Circle';
import { Container, ContainerLeft, ContainerRight, GridCenter, Title } from '../styles';

type ValidationErrors = {
  email?: string;
};

const AuthForgotPassword = () => {
  const { data, isLoading: profileLoading } = useGetUserProfileQuery();
  const location = useLocation<{ redirect?: string }>();
  const [apiMessage, setApiMessage] = useState<ApiMessageData>();
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [formdata, setFormdata] = useState<ForgotPasswordInput>({ email: '' });
  const { mutateAsync, isLoading } = useForgotPasswordMutation();

  const validate = useCallback((input: ForgotPasswordInput): ValidationErrors => {
    const validationErrors: ValidationErrors = {};

    if (input.email === '') {
      validationErrors.email = 'Email is required';
    }

    setErrors(validationErrors);

    return validationErrors;
  }, []);

  const onSubmit = async () => {
    setApiMessage(undefined);

    if (!isEmpty(validate(formdata))) {
      return;
    }

    try {
      await mutateAsync(formdata);

      setFormdata({ email: '' });
      setApiMessage({ success: true, message: 'Please check your email.' });
    } catch (e: any) {
      // apiErrorHandler(e, setApiError);

      if (e.response && e.response.data) {
        if (e.response.data.message) {
          setApiMessage({ success: false, status: e.response.status, message: e.response.data.message });
        }

        if (e.response.data.errors) {
          const { errors } = e.response.data;

          const validationErrors: ValidationErrors = {};

          errors.forEach(({ field, error }: APIValidationError) => {
            switch (field.toLowerCase()) {
              case 'email':
                validationErrors.email = error;
                break;
            }
          });

          setErrors(validationErrors);
        }
      }
    }
  };

  const onChange = useCallback((_, { name, value }) => setFormdata(prev => ({ ...prev, [name]: value })), []);

  if (!profileLoading && data?.user) return <Redirect to="/" />;

  return (
    <GridCenter>
      <Container>
        <ContainerLeft>
          <Link to={{ pathname: '/auth/login', state: location.state }}>
            <Icon name="arrow left" />
            Back
          </Link>

          <Header as="h2">Reset Password</Header>
          <p style={{ color: theme.gray }}>
            Enter the email associated with your account and we'll send an email with the instructions to reset your
            password.
          </p>

          <ApiMessage data={apiMessage} />

          {(!apiMessage || !apiMessage.success) && (
            <Form onSubmit={onSubmit}>
              <Form.Input label="Email" name="email" value={formdata.email} onChange={onChange} error={errors.email} />

              <Button fluid color="blue" loading={isLoading}>
                Send Instructions
              </Button>
            </Form>
          )}
        </ContainerLeft>

        <ContainerRight>
          <Title to="/">bender</Title>
          <Circle />
        </ContainerRight>
      </Container>
    </GridCenter>
  );
};

export default AuthForgotPassword;
