import { createGlobalStyle } from 'styled-components';

import theme from './theme';

export default createGlobalStyle`
  #root {
    height: 100%;
  }

  #root p, .ui p {
    margin: 0 0 1rem;
  }

  code {
    background-color: rgba(0, 0, 0, 0.08);
    border-radius: 3px;
    display: inline-block;
    /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; */
    font-family: 'Monaco', 'Menlo', 'Ubuntu Mono', 'Consolas', 'source-code-pro', monospace;
    font-size: 0.875em;
    font-weight: bold;
    padding: 1px 6px;
    vertical-align: baseline;
  }

  a, button.link {
    color: ${theme.primary};
    cursor: pointer;
    text-decoration: none;
    border: none;
    background: transparent;
    padding: 0;
    font-weight: inherit;
    appearance: none;
    font-family: inherit;
  }
  a:hover, button.link:hover {
    color: ${theme.primaryHover};
  }

  .ui.basic.blue.button, .ui.basic.blue.buttons .button {
    -webkit-box-shadow: 0 0 0 1px ${theme.primary} inset !important;
    box-shadow: 0 0 0 1px ${theme.primary} inset !important;
    color: ${theme.primary} !important;
  }
  .ui.basic.blue.button:hover, .ui.basic.blue.buttons .button:hover,
  .ui.basic.blue.button:focus, .ui.basic.blue.buttons .button:focus {
    -webkit-box-shadow: 0 0 0 1px ${theme.primaryHover} inset !important;
    box-shadow: 0 0 0 1px ${theme.primaryHover} inset !important;
    color: ${theme.primaryHover} !important;
  }

  .ui.blue.button, .ui.blue.buttons .button {
    background-color: ${theme.primary};
  }
  .ui.blue.button:hover, .ui.blue.buttons .button:hover,
  .ui.blue.button:focus, .ui.blue.buttons .button:focus {
    background-color: ${theme.primaryHover};
  }

  i.blue.icon {
    color: ${theme.primary} !important;
  }

  .ui.modals.dimmer {
    z-index: 10000;
  }

  .ui.vertical.divider.full:after,
  .ui.vertical.divider.full:before {
    height: calc(100%);
  }
`;
